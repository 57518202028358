import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    topic: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid.";
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    if (!formData.topic) newErrors.topic = "Please select a topic.";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      emailjs
        .send(
          "service_t5dddha", // Replace with your EmailJS service ID
          "template_c6xh70h", // Replace with your EmailJS template ID
          formData,
          "P6Uy0NAlZ0TmbAnmd" // Replace with your EmailJS public key
        )
        .then(
          (response) => {
            console.log("Email sent successfully!", response);
            setSubmitted(true);
            setFormData({ name: "", email: "", message: "", topic: "" });
            setErrors({});
          },
          (error) => {
            console.error("Failed to send email.", error);
          }
        );
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div id="contact" className="pb-20">
      <h2 className="text-[19.211vw] md:text-[8.611vw] tracking-tight text-center font-display leading-tight w-full mb-4">
        Questions?
      </h2>
      <div className="mb-12 text-2xl text-center">You know the drill</div>

      {submitted && <p className="text-green-600 text-center">Email sent successfully!</p>}

      <form onSubmit={handleSubmit} className="w-full px-6 md:px-0 md:w-[34.7%] mx-auto">
        <div>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-6 mb-4 text-xl text-black border-4 border-black rounded-full placeholder-gray"
          />
          {errors.name && <p className="text-red-600">{errors.name}</p>}
        </div>

        <div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-6 mb-4 text-xl text-black border-4 border-black rounded-full placeholder-gray"
          />
          {errors.email && <p className="text-red-600">{errors.email}</p>}
        </div>

        <div>
          <select
            className="w-full p-6 mb-4 text-xl text-black bg-white border-4 border-black rounded-full placeholder-gray"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
          >
            <option value="">Select a Topic</option>
            <option value="New Business">New Business</option>
            <option value="Careers">Careers</option>
            <option value="Press">Press</option>
            <option value="Speaking">Speaking</option>
          </select>
          {errors.topic && <p className="text-red-600">{errors.topic}</p>}
        </div>

        <div>
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-6 mb-4 text-xl text-black border-4 border-black rounded-xl placeholder-gray"
          />
          {errors.message && <p className="text-red-600">{errors.message}</p>}
        </div>

        <button
          type="submit"
          className="block w-full py-4 text-center text-white bg-black border-4 border-black rounded-full hover:bg-white hover:text-black"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;